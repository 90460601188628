.card-link {
    text-decoration: none;
  }
  
  .card {
    width: 400px;
    height: 150px;
    padding: 0;
    border: 3px solid transparent;
    border-radius: 15px;
    overflow: hidden;
    margin : 5px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .card img {
    width: 100%; 
    height: 100%; 
    border-radius: 10px;
    object-fit: cover; 
  }
  
  .card:hover {
    border-color: white;
    transition: border-color 0.3s ease-in-out;
  }
  