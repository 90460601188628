  .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 250px;
    height: auto;
    margin-right: 20px;
  }
  
  .name {
    font-size: 4em;
    font-weight: 600;
    font-family: 'Bebas Neue', sans-serif;
  }
  
  .cursor::after {
    content: '|';
    display: inline-block;
    animation: blinkCursor 0.50s infinite;
  }
  
  @keyframes blinkCursor {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  