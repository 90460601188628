/* Footer.css */
footer {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
  
  .email-link {
    color: white;
    text-decoration: underline;
  }
  