.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.heading {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.explanation {
  font-size: 1.5rem;
  padding: 1px;
  margin: 5px;
}

.goBack {
    color: white;
    text-decoration: underline;
  }
  