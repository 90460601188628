.App {
  text-align: center;
  background-color: #0A0140;
  color: white;
  padding: 50px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  padding: 20px;
}
